import React from 'react'
import { DiscussionEmbed } from 'disqus-react'
import { CatIcon, DateIcon, ReadIcon } from '../components/Icons'
import { Paper, Typography, Grid, Divider } from '@mui/material'
import Seo from 'gatsby-plugin-wpgraphql-seo'
import Layout from '../layout'
import CategoryTag from '../components/CategoryTag'
import { formatDate, styles } from '../utils'
import SeoTag from '../components/Seo'
import setUpShortcode from '../assets/wp_plugins/js/shortcodes-ultimate'

setUpShortcode()

const Page = ({ pageContext }) => {
  const { title, content, date, nodeType, slug, seo, id, pagesCustomFields } = pageContext
  const { disableComments = false } = pagesCustomFields

  return (
    <Layout>
      <Seo post={pageContext} />
      <SeoTag title={title} />
      <Paper elevation={8} sx={{ padding: 4 }}>
        <Typography variant='h1' sx={styles.singlePostTitle}>
          {title}
        </Typography>
        <Grid container>
          <Grid item sx={styles.flexCenter}>
            <DateIcon />
            <Typography
              variant='caption'
              sx={{
                display: 'inline-block',
                mr: 1
              }}
            >
              {formatDate(date)}
            </Typography>
          </Grid>
          <Grid item sx={styles.flexCenter}>
            <CatIcon />
            <Grid container spacing={1} sx={styles.flexCenter}>
              <CategoryTag name={nodeType} id={id} slug={slug} />;
            </Grid>
          </Grid>
          <Grid item sx={styles.flexCenter}>
            <ReadIcon />
            <Typography variant='caption'>{`${seo.readingTime} Min Read`}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography variant='body1' component='div' dangerouslySetInnerHTML={{ __html: content }} />
      </Paper>
      {!disableComments && (
        <Paper elevation={8} sx={{ padding: 4, my: 4 }}>
          <Typography variant='h5' sx={{ mb: 2 }}>
            Comments
          </Typography>
          <DiscussionEmbed shortname='https-apksoup-com' config={{ identifier: slug, title }} />
        </Paper>
      )}
    </Layout>
  )
}

export default Page
