/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function Seo ({ description, lang, meta, title, keywords, type, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  const defaultLang = lang ?? 'en_US'
  const metaDescription = description ?? site.siteMetadata.description
  const defaultTitleTemplate = site.siteMetadata?.title
  const defaultMeta = meta?.length ? meta : []

  return (
    <Helmet
      htmlAttributes={{
        lang: defaultLang
      }}
      title={title}
      titleTemplate={defaultTitleTemplate ? `%s ~ ${defaultTitleTemplate}` : null}
      meta={[
        {
          name: 'og:type',
          content: type || 'website'
        },
        {
          name: 'keywords',
          content: keywords
        },
        {
          name: 'description',
          content: metaDescription
        },
        {
          property: 'og:title',
          content: title
        },
        {
          property: 'og:description',
          content: metaDescription
        },
        {
          name: 'twitter:card',
          content: 'summary'
        },
        {
          name: 'twitter:creator',
          content: title || ''
        },
        {
          property: 'og:image',
          content: image
        },
        {
          name: 'twitter:image',
          content: image || ''
        },
        {
          name: 'twitter:title',
          content: title
        },
        {
          name: 'twitter:description',
          content: metaDescription
        }
      ].concat(defaultMeta)}
    />
  )
}

export default Seo
